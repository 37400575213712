<template>
 <div  class="is-justify-content-center">
    <div >
      <vue3-chart-js
        :id="stackedBarChart.id"
        :type="stackedBarChart.type"
        :data="stackedBarChart.data"
        :options="options"
        ref="chartRef"
      >
      </vue3-chart-js>
    </div>
  </div>
</template>

<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import {handleFilter} from "@/use/useFilter";
import { onMounted, ref } from 'vue-demi';

export default {
  components: {
    Vue3ChartJs
  },
  props: {
    data: {
      type: Object
    },
  },

  setup (props) {

    const stackedBarChart = {
      id: 'bar-issues-state',
      type: 'bar',
      data: props.data
    }

    const options = {
      plugins: {
        responsive: true,
        legend: {
          position: 'bottom'
        }
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          min: 0,
          max: 100
        }
      }
    }


    return {
      stackedBarChart,
      options
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
